<template>
  <div class="tabled">
    <div class="tabled-cell Nunito">
      <img src="https://i.giphy.com/media/xULW8MYvpNOfMXfDH2/giphy.webp">
      <h1 class="title is-size-3">Hold it! Back it up for a moment.</h1>
      <h1 style="margin-bottom: 10px;" class="subtitle is-size-5 has-text-grey-light">Are you sure you’d like to visit <code style="background-color: var(--main-color-bg-2);">{{ (this.link ? (this.link.host || this.link.hostname) : this.$route.query.to) }}</code>?<br>You will be leaving Grace, and this website may be insecure!</h1>
      <div class="buttons is-centered">
        <b-button type="is-primary" icon-pack="fas" icon-left="arrow-left" @click="goto(true)" style="margin-right: 10px;">Go Back</b-button>
        <b-button type="is-success" icon-pack="fas" icon-left="arrow-right" @click="goto()">Continue</b-button>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'redirect',
    components: {},
    data () {
      return {
        link: new URL(this.$route.query.to)
      }
    },
    async created () {
      // console.log(this.link, this.$route)
      if ((this.link.host || this.link.hostname) === 'gracebot.net') return this.$router.push(this.link.pathname)
      else if ((this.link.host || this.link.hostname).includes('.gracebot.net')) return this.goto()
    },
    methods: {
      async goto (back = false) {
        if (back) {
          const wentBack = this.$router.go(-1)
          if (!wentBack) this.$router.push('/')
        } else {
          this.link.searchParams.set('ref', 'gracebot.net')
          window.location = this.link.toString()
        }
      }
    }
  }
</script>

<style scoped>
.tabled {
  display: table;
  height: 35rem;
  margin: 0 auto;
}
.tabled-cell {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}
</style>
